import React, { useEffect, useState } from "react";
import useApiUrlTranslation from "./useApiUrlTranslation";
import { PlayCircleIcon } from "@heroicons/react/24/solid";

const VideoListSection = ({ videoListReqParams = null }) => {

    const { t, i18n, apiUrlTranslation } = useApiUrlTranslation();

    const [videoList, setVideoList] = useState(null);

    const [videoSelected, setVideoSelected] = useState(null);

    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    const handleSelectVideo = (video) => {

        setVideoSelected(video)

        setDialogIsOpen(true);
    }

    const getVideosEvents = async () => {

        const params = ` WHERE id_video IN (9647,9648 , 9649, 9650)`
        await fetch(`${apiUrlTranslation}?action=getVideoList&params=${videoListReqParams ?? params}`)
            .then((res) => res.json())
            .then(res => setVideoList(res))
    }

    useEffect(() => {

        getVideosEvents();
    }
        , [i18n.resolvedLanguage]);

    return <div className="px-4">

        <h2 className="animatedgradient font-extrabold py-3">
            Vidéos
        </h2>

        <p>
            Ils soutiennent, investissent et médiatisent les sessions de pitch de l'X'port Hub. myGlobalVillage est fier d'accueillir des personnalités iconiques de la tech, des grandes entreprises mondiales et des médias au sein de son cercle de Tech'Xporters.

        </p>



        <div className="grid grid-1 lg:grid-cols-4 gap-4 ">
            {videoList && videoList.map(video => <div
                onClick={() => handleSelectVideo(video)}
                className="group cursor-pointer  hover:border-x-pink-600   hover:border-y-pink-600  overflow-hidden bg-neutral-600/10  rounded-2xl border-x border-y border-neutral-900">

                <div className="  relative">
                    <img
                        src={video.thumbnail_large_url}
                        alt=""
                        className="transform transition-transform duration-300 group-hover:scale-110 w-full h-auto"
                    />
                    <div className="absolute inset-0 flex items-center justify-center">
                        <PlayCircleIcon className="w-12 h-12 text-red-700 transform transition-transform duration-300  group-hover:scale-150" />
                    </div>
                </div>

                <div className="p-4 text-gray-400">{video.titre}</div>
            </div>)}
        </div>

        {dialogIsOpen && <dialog className=" w-screen h-screen fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4">

            <div className="bg-white rounded-lg overflow-hidden shadow-lg w-full max-w-4xl">
                <button
                    className="absolute top-0 right-0 p-2 text-2xl text-white bg-black bg-opacity-50"
                    onClick={() => { setDialogIsOpen(false) }}>X</button>
                <iframe
                    src={videoSelected && videoSelected.embed_url}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Démarrer la vidéo"
                    className="w-full h-96"
                ></iframe>
            </div>
        </dialog>
        }


    </div>
}

export default VideoListSection;